import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useToast } from 'components/_legacy/ccd-toast';
import { useProgressBar } from 'components/atoms/ProgressBar';
import { getIplTableWithSession, resetIpl } from 'services/api/ipl.service';
import { IplError, IplRequestParams, IplResponseBody } from 'types/ipl';

export const useIplTableData = ({
    collaborationSpaceId,
    projectId,
    revisionId,
    variant,
}: IplRequestParams) => {
    const { setProgressBar } = useProgressBar();
    const { showToast } = useToast();

    const getErrorMessage = (e: IplError) => {
        if (e.response?.data.Errors?.DetailedMessage[0]) {
            return e.response?.data.Errors?.DetailedMessage[0];
        } else if (e.response?.data.Title) {
            return e.response?.data.Title;
        }

        return 'BOM Intelligence service error';
    };

    return useQuery<IplResponseBody, IplError>(
        ['getIplTable', collaborationSpaceId, projectId, revisionId, variant],
        () =>
            getIplTableWithSession({
                collaborationSpaceId,
                projectId,
                revisionId,
                variant,
            }),
        {
            staleTime: 0,
            cacheTime: 0,
            retry: false,
            onError: (e) => {
                showToast({
                    type: 'error',
                    messages: [getErrorMessage(e)],
                    title: 'An error occurred while fetching BOM Intelligence data.',
                });
                setProgressBar(false);
            },
            enabled: !!collaborationSpaceId && !!projectId && !!revisionId,
        }
    );
};

export const useResetIpl = () => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();
    const { setProgressBar } = useProgressBar();

    const getErrorMessage = (e: IplError) => {
        if (e.response?.data.Errors?.DetailedMessage[0]) {
            return e.response?.data.Errors?.DetailedMessage[0];
        } else if (e.response?.data.Title) {
            return e.response?.data.Title;
        }

        return 'BOM Intelligence service error';
    };

    return useMutation({
        mutationFn: resetIpl,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['getBomSettings'] });
            showToast({
                type: 'info',
                messages: ['BOM Intelligence data has been reset.'],
            });
        },
        onError: (e: IplError) => {
            showToast({
                type: 'error',
                messages: [getErrorMessage(e)],
                title: 'An error occurred while resetting BOM Intelligence data.',
            });
        },
        onSettled: () => {
            setProgressBar(false);
        },
    });
};
