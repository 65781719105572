import { useEffect, useState } from 'react';

import { useProgressBar } from 'components/atoms/ProgressBar';
import { useBomDataQuery } from 'services/queries/bom.query';

import BomViewBindingCellRenderer from './BomViewBindingCellRenderer/BomViewBindingCellRenderer';
import { FETCH_AMOUNT } from './consts';

export default function useBomData(
    collaborationSpaceId,
    projectId,
    projectVersion,
    sortDirection,
    initialSortSettings,
    bindingColumn,
    bindingEnabled,
    isSettingsReady,
    columnsOrder,
    initialColumnOrder,
    hiddenColumns,
    provideInitialColumnsOrder,
    initialColumnsWidth,
    textWrap,
    setDefaultFindchips,
    setFindchips,
    findchipsDisplayConfiguration,
    isFindchipsSet,
    isProjectEmpty,
    variant
) {
    const { setProgressBar } = useProgressBar();
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const [defaultColumns, setDefaultColumns] = useState([]);
    const [defaultFindChipsColumn, setDefaultFindChipsColumn] = useState(undefined);
    const [hasMore, setHasMore] = useState(false);
    const [dataCount, setDataCount] = useState(0);
    const [isBindingPresent, setIsBindingPresent] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);
    const [sortColumnData, setSortColumnData] = useState(null);
    const [columnsInfo, setColumnsInfo] = useState([null]);
    // disable progressBar on unmount
    useEffect(() => () => setProgressBar(false), []);

    const setColumnsFromList = (columns, columnWidths, isBindingEnabled, bindColumn) => {
        const newColumns = [];
        const bindingCellRenderer = BomViewBindingCellRenderer.bind(null, textWrap);

        for (const column of columns) {
            const columnWidth = Object.keys(columnWidths).find((x) => x === column)
                ? columnWidths[column]
                : column.length + 115;

            const columnDescription = {
                Header: column,
                accessor: column,
                ...(columnWidth && { width: columnWidth }),
                minWidth: 100,
                ccdSorted: true,
            };

            if (isBindingEnabled && column === bindColumn) {
                columnDescription.Cell = bindingCellRenderer;
                if (columnWidth) {
                    columnDescription.width = columnWidth;
                }
                columnDescription.minWidth = 250;
                setIsBindingPresent(true);
            }

            newColumns.push(columnDescription);
        }

        newColumns.push({
            Header: '',
            accessor: 'empty',
            ccdSorted: false,
        });
        setColumns(newColumns);
    };

    const isInitialSortingSet = (ignoreInitialSorting) => {
        return (
            ignoreInitialSorting === false &&
            initialSortSettings !== undefined &&
            initialSortSettings.sortedColumnName !== undefined &&
            initialSortSettings.sortDirection !== undefined &&
            initialSortSettings.sortDirection !== 'no-sort'
        );
    };

    const isSortingSet = (sortDirection) => {
        return (
            sortDirection !== undefined &&
            sortDirection.column !== undefined &&
            sortDirection.direction !== undefined &&
            sortDirection.direction !== 'no-sort'
        );
    };

    useEffect(() => {
        if (isSortingSet(sortDirection)) {
            setSortColumnData({
                sortOrder: sortDirection.direction === 'ascending' ? 'ASC' : 'DESC',
                sortColumn: sortDirection.column.id,
            });
        } else if (isInitialSortingSet(initialSortSettings)) {
            setSortColumnData({
                sortOrder: initialSortSettings.sortDirection,
                sortColumn: initialSortSettings.sortedColumnName,
            });
        } else {
            setSortColumnData({
                sortOrder: undefined,
                sortColumn: undefined,
            });
        }
    }, [sortDirection, initialSortSettings]);

    const {
        data: bomData,
        error: bomDataError,
        isLoading: isDataBomLoading,
        fetchNextPage: fetchMoreData,
    } = useBomDataQuery({
        collaborationSpaceId,
        projectId,
        projectVersion,
        startFrom: 0,
        pageSize: 40,
        isSettingsReady: isSettingsReady && (sortColumnData !== null || isProjectEmpty),
        ...sortColumnData,
        variant,
    });
    const processContent = (content) =>
        content.map((currentValue) => {
            Object.keys(currentValue).forEach((currentKey) => {
                currentValue[currentKey] = currentValue[currentKey].replace(/&nbsp;/g, ' ');
            });
            return currentValue;
        });

    useEffect(() => {
        if (!isDataBomLoading) {
            setIsEmpty(false);
            setIsError(false);
            if (bomDataError || !bomData || bomData.pages[0].totalSize === 0) {
                setIsEmpty(true);
                setIsLoaded(false);
                setProgressBar(false);
                return;
            }
            const totalSize = bomData?.pages[0]?.totalSize ?? 0;
            const bom = bomData?.pages.map((x) => x.content).flat();

            const columnsNames = bomData?.pages[0].columns;
            setColumnsInfo(columnsNames);
            if ([null, undefined].includes(findchipsDisplayConfiguration?.enabled)) {
                setDefaultFindchips(defaultFindChipsColumn);
            } else {
                setFindchips(findchipsDisplayConfiguration);
            }

            setData(processContent(bom));
            setDefaultColumns(
                bomData?.pages[0].defaultColumns.map((column) => column.name.toLowerCase())
            );
            setDefaultFindChipsColumn(bomData?.pages[0].defaultFindChipsColumn);
            setHasMore(totalSize > FETCH_AMOUNT);

            setDataCount(totalSize);
            setIsLoaded(true);
            setProgressBar(false);
        }
    }, [bomData, bomDataError, isDataBomLoading]);

    useEffect(() => {
        if (columnsInfo && [null, undefined].includes(findchipsDisplayConfiguration?.enabled)) {
            setDefaultFindchips(defaultFindChipsColumn);
        } else {
            setFindchips(findchipsDisplayConfiguration);
        }
    }, [columnsInfo, findchipsDisplayConfiguration]);

    useEffect(() => {
        if (bomData?.pages[0].columns && bomData?.pages[0].defaultColumns && isFindchipsSet) {
            const _defaultColumns = bomData?.pages[0].defaultColumns;
            const _columns = bomData?.pages[0].columns;

            const orderedColumns = [
                ...new Set([..._defaultColumns.map((item) => item.name), ..._columns]),
            ];
            setColumnsFromList(orderedColumns, initialColumnsWidth, bindingEnabled, bindingColumn);
            provideInitialColumnsOrder(
                columnsOrder?.length === 0 ? initialColumnOrder : columnsOrder,
                orderedColumns,
                hiddenColumns
            );
        }
    }, [bomData, isFindchipsSet, bindingColumn, bindingEnabled]);

    useEffect(() => {
        setIsEmpty(false);
        setIsError(false);
    }, [collaborationSpaceId, projectId, projectVersion]);

    useEffect(() => {
        if (bomDataError && bomDataError.response.status !== 400) {
            setIsError(true);
        }
    }, [bomDataError]);

    return {
        fetchMoreData,
        columns,
        data,
        hasMore,
        dataCount,
        isBindingPresent,
        isLoaded,
        isEmpty,
        isError,
        isDataBomLoading,
        defaultColumns,
    };
}
