import { useContext, useEffect, useState } from 'react';

import Box from 'components/atoms/Box';
import { useProgressBar } from 'components/atoms/ProgressBar';
import ProgressSpinner from 'components/atoms/ProgressSpiner';
import { ExportButtonContext } from 'providers/exportPartlistProvider/ExportPartlistProvider';
import { useIplTableData, useResetIpl } from 'services/queries/ipl.query';
import { LoadingStateLabel } from 'types/loadingStateLabel';

import { showEmptyState } from '../BomView/EmptyStates/EmptyStates';
import {
    showConflictState,
    showErrorState,
    showMpnNotFoundState,
    showNoMpnState,
} from './ErrorState/BomIntelligenceErrorStates';

interface IPLViewProps {
    collaborationSpaceId: string;
    projectId: string | undefined;
    revisionId: string;
    variant: string | undefined;
}

export function IPLView({
    collaborationSpaceId,
    projectId,
    revisionId,
    variant,
}: IPLViewProps): JSX.Element {
    const exportContext = useContext(ExportButtonContext);
    const [loadingIframe, setLoadingIframe] = useState(true);
    const { data, isLoading, error, isError, refetch } = useIplTableData({
        collaborationSpaceId,
        projectId,
        revisionId,
        variant,
    });
    const { setProgressBar } = useProgressBar();

    const { mutate: resetIpl } = useResetIpl();

    useEffect(() => {
        exportContext.setIsExportButtonVisible(false);
        exportContext.setIsExportButtonEnabled(false);
        exportContext.setOnExport(() => {});
    }, []);

    const isErrorResponse =
        !error?.response ||
        (error?.response?.status &&
            error?.response?.status < 400 &&
            error?.response?.status >= 500);

    if (isError && isErrorResponse) {
        return showErrorState(error, refetch);
    }

    const isBomEmpty = !isLoading && error?.response?.status === 404 && !data?.data;

    const isMpnNotFound =
        error?.response?.status === 409 &&
        error.response?.data.Errors?.BOM &&
        error.response?.data.Errors?.BOM[0] === 'MPN_NOT_FOUND';

    const isClientError =
        error?.response?.status && error?.response?.status >= 400 && error?.response?.status < 500;

    if (isError && isClientError) {
        if (
            error.response?.data.Errors?.BOM &&
            error.response?.data.Errors?.BOM[0] === 'EMPTY_MPNS'
        ) {
            return showNoMpnState();
        }

        if (isBomEmpty) {
            return showEmptyState();
        }

        if (isMpnNotFound) {
            return showMpnNotFoundState(() =>
                resetIpl({
                    collaborationSpaceId: collaborationSpaceId,
                    projectId: projectId as string,
                    revisionId: revisionId,
                    variant: variant,
                })
            );
        }

        if (error?.response?.status === 409) {
            return showConflictState(error, () =>
                resetIpl({
                    collaborationSpaceId: collaborationSpaceId,
                    projectId: projectId as string,
                    revisionId: revisionId,
                    variant: variant,
                })
            );
        }

        return showErrorState(error, refetch);
    }

    const progressSpinnerVisible = isLoading || (!isError && !data?.data.url);
    const iplVisible = !isLoading && data?.data;

    return (
        <Box
            css={(theme) => ({
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                padding: theme.spacing(0, 0.25, 0, 0.25),
            })}
        >
            {progressSpinnerVisible && <ProgressSpinner label={LoadingStateLabel.IPL} />}
            {iplVisible && (
                <Box
                    css={(theme) => ({
                        height: '100%',
                        width: '100%',
                        boxShadow: `1px 1px 1px ${theme.colors.shadow}`,
                        border: `1px solid ${theme.colors.table.border}`,
                        margin: theme.spacing(1, 0.25, 1, 0.25),
                    })}
                >
                    <iframe
                        width='100%'
                        height='100%'
                        css={{
                            border: 'transparent',
                        }}
                        src={data.data.url.url}
                        allow='fullscreen'
                        onError={() => {
                            setLoadingIframe(false);
                            setProgressBar(false);
                        }}
                        onLoad={() => {
                            setLoadingIframe(false);
                            setProgressBar(false);
                        }}
                    />
                    {loadingIframe && <ProgressSpinner label={LoadingStateLabel.IPL} />}
                </Box>
            )}
        </Box>
    );
}
