import CcdEmptyState from 'components/_legacy/ccd-empty-state';
import Button from 'components/atoms/Button';
import Paragraph from 'components/atoms/Paragraph/Paragraph';
import { ReactComponent as EmptyStateImage } from 'images/Empty-State-icon.svg';
import { ReactComponent as ErrorLabel } from 'images/error_generic.svg';
import { IplError } from 'types/ipl';

import ErrorState from './ErrorState';

const getErrorMessage = (e: IplError) => {
    if (e.response?.data.Errors?.DetailedMessage[0]) {
        return e.response?.data.Errors?.DetailedMessage[0];
    } else if (e.response?.data.Title) {
        return e.response?.data.Title;
    }

    return 'BOM Intelligence service error';
};

export function showErrorState(error: IplError, buttonClick: () => void) {
    return (
        <ErrorState>
            <ErrorLabel />
            <h1>{error?.response?.data.Title}</h1>
            <Paragraph>
                Please try again. If the problem persists, please contact our support.
            </Paragraph>
            <Button onClick={buttonClick}>Retry</Button>
        </ErrorState>
    );
}

export function showNoMpnState() {
    return (
        <CcdEmptyState
            message='Bill of Materials does not contain any Manufacturer Part Number'
            subMessage='Upload a design with correct data'
            icon={EmptyStateImage}
        />
    );
}

export function showMpnNotFoundState(buttonClick: () => void) {
    return (
        <ErrorState>
            <ErrorLabel />
            <h1>Manufacturer part number not found in current BOM</h1>
            <Paragraph>
                Please use the reset button and when the configuration window shows up, select
                proper MPN column in your BOM.
            </Paragraph>
            <Button onClick={buttonClick}>Reset</Button>
        </ErrorState>
    );
}

export function showConflictState(error: IplError, buttonClick: () => void) {
    return (
        <ErrorState>
            <ErrorLabel />
            <h1>{getErrorMessage(error)}</h1>
            <Paragraph>
                Please try to use reset button. If the problem persists, please contact our support.
            </Paragraph>
            <Button onClick={buttonClick}>Reset</Button>
        </ErrorState>
    );
}
